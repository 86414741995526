<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs12 md12>
        <v-text-field
          v-model="notification.id"
          label="Code"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="notification.title"
          label="Title"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="notification.message"
          label="Message"
          :counter="max"
          required
        ></v-text-field>
        <v-combobox
          v-model="notificationSelect"
          :items="$store.state.notifications.notificationType"
          :loading="$store.state.progress"
          item-value="id"
          item-text="value"
          hide-details
          label="Type"
        ></v-combobox>
        <v-combobox
          v-model="daysSelect"
          :items="$store.state.notifications.days"
          :loading="$store.state.progress"
          item-value="id"
          item-text="value"
          hide-details
          multiple
          label="Days"
        ></v-combobox>
        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="notification.hour"
              label="Hour in menu"
              prepend-icon="mdi-clock-time-four-outline"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu2"
            v-model="notification.hour"
            format="24hr"
            @click:minute="$refs.menu.save(hour)"
          ></v-time-picker>
        </v-menu>
        <v-row class="pt-5">
          <v-file-input
            v-model="smallPicture"
            color="deep-green accent-4"
            counter
            @change="smallPictureUpload"
            label="Small Picture"
            placeholder="Select your files"
            prepend-icon="mdi-paperclip"
            outlined
            :show-size="1000"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                v-if="index < 2"
                color="deep-green accent-4"
                dark
                label
                small
                >{{ text }}</v-chip
              >

              <span
                v-else-if="index === 2"
                class="overline grey--text text--darken-3 mx-2"
                >+{{ smallPicture.length - 2 }} File(s)</span
              >
            </template>
          </v-file-input>

          <div style="position:relative;" v-if="notification.smallImage">
            <v-icon
              large
              color="red"
              @click="
                () => {
                  notification.smallImage = null;
                  smallPicture = null;
                }
              "
              style="position:absolute; top:-16px;right:-16px;z-index: 999;"
              >close</v-icon
            >
            <v-img :src="notification.smallImage" max-width="200" />
          </div>
        </v-row>
        <v-row class="pt-5">
          <v-file-input
            v-model="bigPicture"
            color="deep-green accent-4"
            counter
            @change="largePictureUpload"
            label="Big Picture"
            placeholder="Select your files"
            prepend-icon="mdi-paperclip"
            outlined
            :show-size="1000"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                v-if="index < 2"
                color="deep-green accent-4"
                dark
                label
                small
                >{{ text }}</v-chip
              >

              <span
                v-else-if="index === 2"
                class="overline grey--text text--darken-3 mx-2"
                >+{{ bigPicture.length - 2 }} File(s)</span
              >
            </template>
          </v-file-input>

          <div style="position:relative;" v-if="notification.largeImage">
            <v-icon
              large
              color="red"
              @click="
                () => {
                  notification.largeImage = null;
                  bigPicture = null;
                }
              "
              style="position:absolute; top:-16px;right:-16px;z-index: 999;"
              >close</v-icon
            >
            <v-img :src="notification.largeImage" max-width="200" />
          </div>
        </v-row>

        <v-combobox
          v-model="notification.userIds"
          :items="items"
          chips
          clearable
          label="UserIds"
          multiple
          solo
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="remove(item)"
            >
              <strong>{{ item }}</strong>
            </v-chip>
          </template>
        </v-combobox>

        <v-text-field
          v-model="notification.expireTime"
          label="ExpireTime"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="notification.expireCount"
          label="ExpireCount"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="notification.currentCount"
          label="CurrentCount"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="notification.chip"
          type="number"
          label="Chip"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="notification.gold"
          label="Gold"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="notification.vipPoint"
          label="VipPoint"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-combobox
          v-model="notification.segments"
          :items="segments"
          chips
          clearable
          label="Segments"
          multiple
          solo
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="removeSegment(item)"
            >
              <strong>{{ item }}</strong>
            </v-chip>
          </template>
        </v-combobox>
      </v-flex>
      <v-btn color="success" @click="save(false)">Save</v-btn>
      <v-btn color="success" @click="save(true)">Save & Send</v-btn>
    </v-layout>
  </v-container>
</template>
<script>
// import router from "@/router";
export default {
  data: () => ({
    notification: {},
    menu2: false,
    items: [],
    segments: [],
    notifications: [],
    smallPicture: [],
    bigPicture: [],
    notificationSelect: { id: 0, value: "Normal" },
    alert: false,
    max: 120,
    messageCode: "MESSAGE01",
  }),

  methods: {
    smallPictureUpload() {
      if (this.smallPicture)
        this.notification.smallImage = URL.createObjectURL(this.smallPicture);
      else this.notification.smallImage = null;
    },
    remove(item) {
      this.notification.userIds.splice(
        this.notification.userIds.indexOf(item),
        1
      );
      this.notification.userIds = [...this.notification.userIds];
    },
    removeSegment(item) {
      this.notification.segments.splice(
        this.notification.segments.indexOf(item),
        1
      );
      this.notification.segments = [...this.notification.segments];
    },
    largePictureUpload() {
      if (this.bigPicture)
        this.notification.largeImage = URL.createObjectURL(this.bigPicture);
      else this.notification.largeImage = null;
    },
    async save(isSend) {
      this.notification.expireTime = parseInt(
        this.notification.expireTime || 0
      );
      this.notification.expireCount = parseInt(
        this.notification.expireCount || 0
      );
      this.notification.currentCount = parseInt(
        this.notification.currentCount || 0
      );
      this.notification.chip = parseInt(this.notification.chip || 0);
      this.notification.gold = parseInt(this.notification.gold || 0);
      this.notification.vipPoint = parseInt(this.notification.vipPoint || 0);
      this.notification.type = this.notificationSelect.id;
      // eslint-disable-next-line no-console
      console.log(this.daysSelect);
      this.notification.days = null;
      let formData = new FormData();

      Object.keys(this.notification).forEach((item) => {
        if (this.notification[item]) {
          if (item == "userIds" || item == "segments") {
            this.notification[item].forEach((element) => {
              formData.append(item, element);
            });
          } else formData.append(item, this.notification[item]);
        }
      });
      if (this.daysSelect) {
        this.daysSelect.forEach((a) => {
          formData.append("days", a.id);
        });
      }

      formData.append("smallPicture", this.smallPicture);
      formData.append("bigPicture", this.bigPicture);

      await this.$store.dispatch("notifications/createOrUpdate", {
        data: formData,
        isSend,
      });
      this.$store.dispatch("setAlert");

      // router.push("/notifications");
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  computed: {},

  async created() {
    if (this.$route.params.id) {
      await this.$store.dispatch("notifications/get", this.$route.params.id);
      this.notification = this.$store.state.notifications.detail;
      this.notificationSelect = this.$store.state.notifications.notificationType.find(
        (m) => m.id == this.notification.type
      );
      this.daysSelect = this.$store.state.notifications.days.filter(
        (m) => this.notification.days.indexOf(m.id) >= 0
      );
    }
  },
};
</script>
